<template>
  <v-container id="passwordReset" class="fill-height justify-center" tag="section">
    <v-row justify="center">
      <v-slide-y-transition appear>
        <login-pages-card max-width="100%" width="440" class="py-1">
          <p class="pt-5 display-1 font-weight-bold text-center text-uppercase">
            {{ $t('password-reset') }}
          </p>
          <template v-if="!token">
            <p class="pt-3 px-lg-10 px-sm-5 font-weight-light text-center">
              {{ $t('reset-pass') }}
            </p>
            <v-card-text class="text-center px-lg-10 px-sm-5">
              <validation-observer v-slot="{invalid, handleSubmit}" slim>
                <v-form @submit.prevent="handleSubmit(sendResetEmail)">
                  <validation-provider v-slot="{errors}" rules="required|email">
                    <v-text-field
                      id="email"
                      v-model="email"
                      :label="$t('email')"
                      name="email"
                      prepend-icon="mdi-email"
                      :error-messages="errors"
                      class="pb-lg-10 pb-sm-5"
                      :disabled="isLoading"
                      @input="isLoaded ? clearLinkRequestLoaded() : null"
                    >
                      <template v-if="isLoaded" v-slot:append>
                        <v-icon color="green"> mdi-check </v-icon>
                      </template>
                    </v-text-field>
                  </validation-provider>
                  <v-btn
                    outlined
                    color="#797979"
                    class="mb-5 font-weight-bold"
                    rounded
                    width="95%"
                    type="submit"
                    :loading="isLoading"
                    :disabled="invalid || isLoading"
                  >
                    {{ $t('reset') }}
                  </v-btn>
                </v-form>
              </validation-observer>

              <v-btn
                plain
                color="#797979"
                class="font-weight-bold mb-5"
                rounded
                width="95%"
                :to="loginRouteTo"
              >
                {{ $t('login-now') }}
              </v-btn>
            </v-card-text>
          </template>
          <template v-else>
            <p class="pt-3 px-lg-10 px-sm-5 font-weight-light text-center">
              {{ $t('chooseNewPassword') }}
            </p>
            <v-card-text class="text-center px-lg-10 px-sm-5">
              <validation-observer v-slot="{invalid, handleSubmit}">
                <v-form @submit.prevent="handleSubmit(resetPassword)">
                  <validation-provider v-slot="{errors}" rules="required|email">
                    <v-text-field
                      v-model="email"
                      :label="$t('email')"
                      name="email"
                      prepend-icon="mdi-email"
                      :error-messages="errors"
                      :disabled="isPasswordChanging"
                    />
                  </validation-provider>
                  <validation-provider
                    v-slot="{errors}"
                    :rules="{regex: passwordRegex, required: true}"
                    vid="password"
                  >
                    <v-text-field
                      v-model="password"
                      :label="$t('new-password')"
                      type="password"
                      :error-messages="errors"
                      :disabled="isPasswordChanging"
                      prepend-icon="mdi-lock-outline"
                    />
                  </validation-provider>
                  <validation-provider
                    v-slot="{errors}"
                    :rules="{required: true, confirmed: 'password'}"
                  >
                    <v-text-field
                      v-model="passwordConfirm"
                      :label="$t('retype-new-password')"
                      type="password"
                      :error-messages="errors"
                      :disabled="isPasswordChanging"
                      prepend-icon="mdi-lock-outline"
                    />
                  </validation-provider>
                  <v-btn
                    class="mb-5 font-weight-bold"
                    color="secondary"
                    rounded
                    width="100%"
                    :loading="isPasswordChanging"
                    :disabled="invalid || isPasswordChanging"
                    type="submit"
                  >
                    {{ $t('reset') }}
                  </v-btn>
                  <v-btn
                    plain
                    color="#797979"
                    class="font-weight-bold mb-5"
                    rounded
                    width="100%"
                    :to="loginRouteTo"
                  >
                    {{ $t('login-now') }}
                  </v-btn>
                </v-form>
              </validation-observer>
            </v-card-text>
          </template>
        </login-pages-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
import {requestPasswordResetLink, resetPassword} from '@/auth/api';
import {LoadingFlag} from '@/loading/types/LoadingFlags';
import {LOGIN} from '@/router/route-names';
import {PASSWORD_REGEX} from '@/form-validation/utils/vee-validation-rules-extension';
import LoginPagesCard from '@/auth/components/LoginPagesCard';

export default {
  name: 'PasswordReset',
  components: {LoginPagesCard},
  data: () => ({
    email: null,
    password: null,
    passwordConfirm: null,
    passwordRegex: PASSWORD_REGEX,
  }),
  computed: {
    isLoading() {
      return this.$loadingFlags.isLoading(LoadingFlag.PasswordResetRequest);
    },
    isLoaded() {
      return this.$loadingFlags.isLoaded(LoadingFlag.PasswordResetRequest);
    },
    loginRouteTo() {
      return {name: LOGIN};
    },
    token() {
      return this.$route.params.token;
    },
    isPasswordChanging() {
      return this.$loadingFlags.isLoading(LoadingFlag.ChangingPassword);
    },
  },
  mounted() {
    this.clearLinkRequestLoaded();
  },
  methods: {
    clearLinkRequestLoaded() {
      this.$loadingFlags.clearFlags(LoadingFlag.PasswordResetRequest);
    },
    sendResetEmail() {
      this.$loadingFlags.loadingHandler(LoadingFlag.PasswordResetRequest, () =>
        requestPasswordResetLink(this.email).then(() => {
          this.$successSnackbar.open({
            messageTranslationKey: 'successSnackbar.resetEmailSent',
            showButton: true,
          });
        })
      );
    },
    resetPassword() {
      const {email, password, token} = this;
      this.$loadingFlags
        .loadingHandler(LoadingFlag.ChangingPassword, () => resetPassword(email, password, token))
        .then(() => {
          this.$router.push({name: LOGIN});
        })
        .catch((err) => {
          if (err && err.response && err.response.status === 400) {
            return this.$errorDialog.open({
              message: this.$t('invalidReset.text'),
              title: this.$t('invalidReset.title'),
            });
          }
          return this.$errorReporting.errorDialogHandler(err);
        });
    },
  },
};
</script>
