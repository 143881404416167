var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height justify-center",attrs:{"id":"passwordReset","tag":"section"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-slide-y-transition',{attrs:{"appear":""}},[_c('login-pages-card',{staticClass:"py-1",attrs:{"max-width":"100%","width":"440"}},[_c('p',{staticClass:"pt-5 display-1 font-weight-bold text-center text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t('password-reset'))+" ")]),(!_vm.token)?[_c('p',{staticClass:"pt-3 px-lg-10 px-sm-5 font-weight-light text-center"},[_vm._v(" "+_vm._s(_vm.$t('reset-pass'))+" ")]),_c('v-card-text',{staticClass:"text-center px-lg-10 px-sm-5"},[_c('validation-observer',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.sendResetEmail)}}},[_c('validation-provider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pb-lg-10 pb-sm-5",attrs:{"id":"email","label":_vm.$t('email'),"name":"email","prepend-icon":"mdi-email","error-messages":errors,"disabled":_vm.isLoading},on:{"input":function($event){_vm.isLoaded ? _vm.clearLinkRequestLoaded() : null}},scopedSlots:_vm._u([(_vm.isLoaded)?{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check ")])]},proxy:true}:null],null,true),model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('v-btn',{staticClass:"mb-5 font-weight-bold",attrs:{"outlined":"","color":"#797979","rounded":"","width":"95%","type":"submit","loading":_vm.isLoading,"disabled":invalid || _vm.isLoading}},[_vm._v(" "+_vm._s(_vm.$t('reset'))+" ")])],1)]}}],null,false,3888199526)}),_c('v-btn',{staticClass:"font-weight-bold mb-5",attrs:{"plain":"","color":"#797979","rounded":"","width":"95%","to":_vm.loginRouteTo}},[_vm._v(" "+_vm._s(_vm.$t('login-now'))+" ")])],1)]:[_c('p',{staticClass:"pt-3 px-lg-10 px-sm-5 font-weight-light text-center"},[_vm._v(" "+_vm._s(_vm.$t('chooseNewPassword'))+" ")]),_c('v-card-text',{staticClass:"text-center px-lg-10 px-sm-5"},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.resetPassword)}}},[_c('validation-provider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('email'),"name":"email","prepend-icon":"mdi-email","error-messages":errors,"disabled":_vm.isPasswordChanging},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":{regex: _vm.passwordRegex, required: true},"vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('new-password'),"type":"password","error-messages":errors,"disabled":_vm.isPasswordChanging,"prepend-icon":"mdi-lock-outline"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":{required: true, confirmed: 'password'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('retype-new-password'),"type":"password","error-messages":errors,"disabled":_vm.isPasswordChanging,"prepend-icon":"mdi-lock-outline"},model:{value:(_vm.passwordConfirm),callback:function ($$v) {_vm.passwordConfirm=$$v},expression:"passwordConfirm"}})]}}],null,true)}),_c('v-btn',{staticClass:"mb-5 font-weight-bold",attrs:{"color":"secondary","rounded":"","width":"100%","loading":_vm.isPasswordChanging,"disabled":invalid || _vm.isPasswordChanging,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('reset'))+" ")]),_c('v-btn',{staticClass:"font-weight-bold mb-5",attrs:{"plain":"","color":"#797979","rounded":"","width":"100%","to":_vm.loginRouteTo}},[_vm._v(" "+_vm._s(_vm.$t('login-now'))+" ")])],1)]}}])})],1)]],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }